/**
 * @description
 * - Provides constants for redirect and routes.
 *
 * @example
 * * Example         Path
 * *
 * * /articles/15    articlePath(id)
 * *
 * * /articles       articlesPath
 * *
 * * Nested values
 * *
 * * /articles/15/containers/25 -> /articles/articlesId:/containers/:id
 *
 *
 * Naming Examples:
 * * view   -> warehousesViewPath
 * * edit   -> warehousesEditPath
 * * all    -> warehousesAllPath
 * * search -> warehousesSearchPath
 */

export const URLS = {
  leaveChunker: {
    linkedin: 'https://www.linkedin.com/company/chunker',
    facebook: 'https://www.facebook.com/Chunker-214748239082564/',
    wwwchunker: 'https://www.chunker.com/',
    googleMaps: (address) => `https://www.google.com/maps?q=${address}`,
  },
  termsAndServicesPath: '/terms',
  privacyPolicyPath: '/terms/privacy_policy',
  contactUsPath: '/contact-us',

  loginPath: '/login',
  registerPath: '/register',

  warehousesSearchPath: `/search`,
  warehousesViewPath: (id) => `/view/${id}`,
  warehousesAllPath: '/list',

  propertiesPath: '/properties',
  propertyEditPath: (id) => `/propertyedit/${id}`,

  listingsPath: '/list',
  expiredListingDetailPath: '/expired_listing_detail',

  boxSitesSearchPath: `/containers/search`,
  boxSitePath: (id) => `/container-sites/${id}`,
  boxSiteEditPath: (id) => `/admin/container-sites/${id}/edit`,
  boxSitesPath: `/admin/container-sites`,

  licensesAllPath: '/licenses',
  licenseStartsNewPath: '/licensing-wizard',
  licenseViewPath: (id) => `/licensing-wizard/${id}`,

  listingEditPath: (id) => `/edit/${id}`,

  get onLoginSuccessRedirectPath() {
    return this.warehousesSearchPath
  },
}
