import { AppBar, Badge, Grid, Hidden, Toolbar } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'
/* eslint-disable react/no-unused-state,react/prop-types,react/destructuring-assignment,react/no-did-update-set-state */
// TODO: JVD: resolve suppressed linter errors
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getListings, getUserStatus } from '../../ducks/reducers/main_reducer'

import Constants from './constants'
import DialogRouter from '../DialogRouter'
import LoggedOutButtons from './LoggedOutButtons'
import LoggerHOC from '../../HigherOrderComponents/Logger'
import MobileDrawer from './MobileDrawer'
import NavExpandableMenuButton from './navExpandableManuButton'
import Tour from '../../containers/tourWizard/index'
import UserMenu from './UserMenu'
import { getAllOffers } from '../../services/searchService'
import logo from '../../images/artifacts/OrangeChunkerBadge.png'
import { offerRequiresAction } from '../ListingOfferTable'
import { registerHeapEvent } from '../../utilities/RegisterHeapEvent'
import { updateCount } from '../../ducks/reducers/dealNegotioationReducer'
import { UserContext } from '../../context/UserContext'
import { URLS } from '../../core/urls'

const styles = {
  whiteNavButton: {
    fontFamily: 'Saira-Condensed-Bold',
    fontSize: '14px',
    textTransform: 'uppercase',
    height: '100%',
    width: '100%',
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    borderRadius: '24px',
    '&:hover': {
      color: '#e0e0e0',
      cursor: 'pointer',
    },
  },
  orangeNavButton: {
    fontFamily: 'Saira-Condensed-Bold',
    fontSize: '14px',
    textTransform: 'uppercase',
    height: '100%',
    width: '100%',
    color: '#ed8b00',
    textDecoration: 'none',
    display: 'block',
    margin: '0',
    padding: '4px 8px',
    borderRadius: '24px',
    backgroundColor: 'white',
    '&:hover': {
      // color: "#ffcd00",
      cursor: 'pointer',
      backgroundColor: '#eee',
    },
  },
}

class SimpleAppBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      currentHash: '',
      displayName: '',
      isRegisterForm: false,
      anchorEl: null,
      isModalOpen: false,
      showTourDialog: false,
      tourDialogHasBeenShown: false,
      customSignupMessage: '',
      stepsForSignupDialog: [],
      licenseOffersHaveBeenSet: false,
      isSupport: false,
    }
  }

  componentDidMount() {
    const {
      status: { isUserLoggedIn },
    } = this.context

    if (isUserLoggedIn) {
      this.setLicenseOffers()
      this.setState({ licenseOffersHaveBeenSet: true })
    }
  }

  // refactor to fix  react/no-did-update-set-state
  componentDidUpdate() {
    const {
      status: { isUserLoggedIn },
    } = this.context

    if (isUserLoggedIn && !this.state.licenseOffersHaveBeenSet) {
      this.setLicenseOffers()
      this.setState({ licenseOffersHaveBeenSet: true })
    }
  }

  componentWillUnmount() {
    this.unlisten()
  }

  unlisten = () => {
    this.props.history.listen((location) => {
      this.setState({ currentHash: location.pathname })
    })
  }

  setLicenseOffers = () => {
    // GET NUMBER OF OFFERS
    // THIS WILL UPDATE IF USE HITS THE LICENSES TAB OR DELETES OR ACCEPTS AN OFFER
    getAllOffers().then((_offers) => {
      const numOffersRequiringAction = _offers.reduce(
        (total, currentOffer) => (offerRequiresAction(currentOffer) ? total + 1 : total),
        0,
      )
      this.props.updateCount(numOffersRequiringAction)
    })
  }

  // eslint-disable-next-line
  setDocumentTitle = (count) => {
    if (count) {
      document.title = count > 1 ? `Chunker - ${count} New Messages` : 'Chunker - 1 New Message'
    } else {
      document.title = 'Chunker - List, locate, and license short-term warehouse space instantly'
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  renderRoute = (objRoute, intIndex) => {
    const isActive = window.location.pathname === objRoute.path
    const renderLink = () => (
      <Link
        id={objRoute.key}
        className={this.props.classes.whiteNavButton}
        to={objRoute.path}
        style={isActive ? { color: '#ed8b00' } : null}
        onClick={() => registerHeapEvent('Button Click', 'button_name', objRoute.label)}
      >
        {objRoute.label}
      </Link>
    )

    if (objRoute.subLinks) {
      return (
        <Grid item key={`nav_${intIndex}`}>
          <NavExpandableMenuButton
            history={this.props.history}
            subLinks={objRoute.subLinks}
            label={objRoute.label}
          />
        </Grid>
      )
    }

    return (
      <Grid item key={`nav_${intIndex}`}>
        {this.props.nav.count && objRoute.label === 'Licenses' && this.props.nav.count > 0 ? (
          <Badge badgeContent={this.props.nav.count} color="primary">
            {renderLink()}
          </Badge>
        ) : (
          renderLink()
        )}
      </Grid>
    )
  }

  renderRoutes = () => {
    const { status } = this.context
    if (status.isInitializing || !status.isUserLoggedIn) return null
    return Constants.authRoutes.map(this.renderRoute)
  }

  toggleTourDialog = () => {
    this.handleClose()
    this.setState((state) => ({
      showTourDialog: !state.showTourDialog,
    }))
  }

  handleSignupPress = () => this.props.history.push('/register')

  handleLoginPress = () => this.props.history.push('/login')

  render() {
    const {
      status: { isUserLoggedIn, isInitializing },
    } = this.context

    return (
      <div>
        <DialogRouter
          isModalOpen={this.state.showTourDialog}
          handleRequestClose={this.toggleTourDialog}
          fullWidth
        >
          <Tour history={this.props.history} />
        </DialogRouter>
        <AppBar position="fixed" style={{ backgroundColor: '#83786f' }} elevation={0}>
          <Hidden mdDown>
            <Grid container justifyContent="center">
              <Toolbar id="main-site-nav" style={{ width: '1100px' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Link
                      to={{ pathname: URLS.leaveChunker.wwwchunker }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="logo"
                        src={logo}
                        style={{
                          height: '30px',
                          marginRight: '8px',
                          marginTop: '2px',
                        }}
                      />
                    </Link>
                    {this.renderRoutes()}
                    {isInitializing ? null : isUserLoggedIn ? (
                      <UserMenu />
                    ) : (
                      <LoggedOutButtons
                        classes={this.props.classes}
                        handleSignupPress={this.handleSignupPress}
                        handleLoginPress={this.handleLoginPress}
                      />
                    )}

                    {/* {this.renderAuthAction()} */}
                  </Grid>
                </Grid>
              </Toolbar>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Toolbar className="navbar_mobile">
              <div className="navbar_chunker_logo">
                <Link
                  to={{ pathname: URLS.leaveChunker.wwwchunker }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="logo" src={logo} style={{ height: '30px' }} />
                </Link>
              </div>
              <MobileDrawer
                history={this.props.history}
                onLoginPress={this.handleLoginPress}
                onSignupPress={this.handleSignupPress}
              />
            </Toolbar>
          </Hidden>
        </AppBar>
      </div>
    )
  }
}

SimpleAppBar.contextType = UserContext

function mapStateToProps({ nav }) {
  return {
    nav,
  }
}

export default LoggerHOC(
  connect(mapStateToProps, { getUserStatus, getListings, updateCount })(
    withRouter(withStyles(styles)(SimpleAppBar)),
  ),
)
